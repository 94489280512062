import React, { useContext, useEffect, useState } from 'react';
import PageWrapperFluid from '../../Components/PageWrapperFluid';
import PageTitle from '../../Components/PageTitle';
import globalClasses from '../../App.module.css';
import AppTable from '../../Components/AppTable/index';
import { Table } from '@mantine/core';
import { AppContext } from '../../Context/AppContext';
import OrcImage from '../../Assets/boss/orc.png';
import MilgyoImage from '../../Assets/boss/milgyo.png';
import BossImage from '../../Assets/boss/boss.png';
import MetinsImage from '../../Assets/boss/metins.png';
import UndeadImage from '../../Assets/boss/undead.png';
import PvPImage from '../../Assets/boss/pvp.png';
import PvMImage from '../../Assets/boss/pvm.png';
import RankingImage from '../../Components/RankingImage';

const Ranking = () => {
  const { getCharClass, getEmpire, generalRanking } =
    useContext(AppContext);

    const [superboss, setSuperboss] = useState([]);
    const [semanal, setSemanal] = useState([]);
    const [metin, setMetin] = useState([]);
    const [mundial, setMundial] = useState([]);
    const [caca, setCaca] = useState([]);
    const [mob, setMob] = useState([]);
    const [fish, setFish] = useState([]);
    const [mine, setMine] = useState([]);
    const [refine, setRefine] = useState([]);
    const [destroy, setDestroy] = useState([]);
    const [event, setEvent] = useState([]);

  useEffect(() => {
    if (generalRanking) {
      const selectedSuper = generalRanking["GR_SUPERBOSS"] || [];
      const selectedSemanal = generalRanking["GR_WEEKBOSS"] || [];
      const selectedMetin = generalRanking["GR_METIN"] || [];
      const selectedMundial = generalRanking["GR_BOSS"] || [];
      const selectedCaca = generalRanking["GR_HUNT"] || [];
      const selectedMob = generalRanking["GR_MOB"] || [];
      const selectedOre = generalRanking["GR_ORE"] || [];
      const selectedFish = generalRanking["GR_FISH"] || [];
      const selectedRefine = generalRanking["GR_REFINE"] || [];
      const selectedEvent = generalRanking["GR_EVENT"] || [];
      const selectedDestroy = generalRanking["GR_DESTROY"] || [];

      setSuperboss(selectedSuper);
      setSemanal(selectedSemanal);
      setMetin(selectedMetin);
      setMundial(selectedMundial);
      setCaca(selectedCaca);
      setMob(selectedMob);
      setFish(selectedFish);
      setMine(selectedOre);
      setRefine(selectedRefine);
      setDestroy(selectedDestroy);
      setEvent(selectedEvent);
    }
  }, [generalRanking])

  const heads = ['Classe', 'Reino', 'Nome', 'Pontos'];

  const superbossRows = superboss.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const semanalRows = semanal.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const metinRows = metin.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const mundialRows = mundial.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const cacaRows = caca.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const mobRows = mob.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const fishRows = fish.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const mineRows = mine.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const refineRows = refine.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const destroyRows = destroy.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));

  const eventRows = event.map((element) => (
    <Table.Tr key={element.player_id}>
      <Table.Td>{getCharClass(element.player?.job)}</Table.Td>
      <Table.Td>{getEmpire(element.player?.account?.index?.empire)}</Table.Td>
      <Table.Td>{element.player_name}</Table.Td>
      <Table.Td>{element.ranking_info}</Table.Td>
    </Table.Tr>
  ));
  // const bossRows = bosses.map((element) => (
  //   <Table.Tr key={element.name}>
  //     <Table.Td>{element.classe}</Table.Td>
  //     <Table.Td>{element.reino}</Table.Td>
  //     <Table.Td>{element.name}</Table.Td>
  //     <Table.Td>{element.kills}</Table.Td>
  //   </Table.Tr>
  // ));
  // const pvpRows = pvps.map((element) => (
  //   <Table.Tr key={element.name}>
  //     <Table.Td>{element.classe}</Table.Td>
  //     <Table.Td>{element.reino}</Table.Td>
  //     <Table.Td>{element.name}</Table.Td>
  //     <Table.Td>{element.kills}</Table.Td>
  //   </Table.Tr>
  // ));
  // const lvlRows = players.map((element) => (
  //   <Table.Tr key={element.name}>
  //     <Table.Td>{element.classe}</Table.Td>
  //     <Table.Td>{element.reino}</Table.Td>
  //     <Table.Td>{element.name}</Table.Td>
  //     <Table.Td>{getPatente(element.elo)}</Table.Td>
  //     <Table.Td>{element.elo}</Table.Td>
  //   </Table.Tr>
  // ));

  return (
    <div style={{ width: '100%' }}>
      <PageTitle>Rankings</PageTitle>
      <PageWrapperFluid>
        <div className={globalClasses.rowBox}>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Eventos</h2>
            <AppTable heads={heads} rows={eventRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Destruir Item</h2>
            <AppTable heads={heads} rows={destroyRows} />
          </div>
        </div>

        <div className={globalClasses.rowBox}>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Boss Semanal</h2>
            <AppTable heads={heads} rows={semanalRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Super Boss</h2>
            <AppTable heads={heads} rows={superbossRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Boss Mundial</h2>
            <AppTable heads={heads} rows={mundialRows} />
          </div>
        </div>

        <div className={globalClasses.rowBox}>  
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Metins</h2>
            <AppTable heads={heads} rows={metinRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Missão de Caça</h2>
            <AppTable heads={heads} rows={cacaRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Mobs</h2>
            <AppTable heads={heads} rows={mobRows} />
          </div>
        </div>

        <div className={globalClasses.rowBox}>  
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Pesca</h2>
            <AppTable heads={heads} rows={fishRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Mineração</h2>
            <AppTable heads={heads} rows={mineRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Refinação</h2>
            <AppTable heads={heads} rows={refineRows} />
          </div>
        </div>

        <div className={globalClasses.rowBox}>  
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Pesca</h2>
            <AppTable heads={heads} rows={fishRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Mineração</h2>
            <AppTable heads={heads} rows={mineRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 5 Refinação</h2>
            <AppTable heads={heads} rows={refineRows} />
          </div>
        </div>
      </PageWrapperFluid>
    </div>
  );
};

export default Ranking;
