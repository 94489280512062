import React, { useContext, useEffect } from 'react';
import PageWrapperFluid from '../../Components/PageWrapperFluid';
import PageTitle from '../../Components/PageTitle';
import Button from '../../Components/Button';
import BR from '../../Assets/br.png';
import globalClasses from '../../App.module.css';
import Signup from '../../Components/Forms/Signup';
import { AppContext } from '../../Context/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../Components/PageWrapper';

const Download = () => {
  const { isAuth } = useContext(AppContext);

  const handleDownload = () => {
    const url =
      'https://drive.google.com/file/d/1sg-163eA6jZy9ec_2JtAySZWjfQK_5nA/view?usp=sharing';
    window.open(url, '_blank');
  };

  return (
    <div>
      <PageTitle>Jogue gratis</PageTitle>
      <PageWrapper>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleDownload} highlight>
            <img alt="Brasil" src={BR} />
            Baixar para windows
          </Button>
        </div>
      </PageWrapper>
    </div>
  );
};

export default Download;
