import React, { useContext, useEffect, useState } from 'react';
import PageTitle from '../../Components/PageTitle';
import PageWrapperFluid from '../../Components/PageWrapperFluid';
import LabelFragment from '../../Components/LabelFragment';
import Button from '../../Components/Button';
import { AppContext } from '../../Context/AppContext';
import { useNavigate } from 'react-router-dom';
import classes from './index.module.css';
import WhatsAppIcon from '../../Components/WhatsApp';
import PageWrapper from '../../Components/PageWrapper';

const Contest = () => {
  const { cashout, statistics } = useContext(AppContext);

  const doGroups = () => {
    const url = 'https://linktr.ee/mt2dominium';
    window.open(url, '_blank');
  };

  const [countdown, setCountdown] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDifference = cashout - now;

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setCountdown(
        `${days} dias ${hours} horas ${minutes} minutos ${seconds} segundos`,
      );

      if (timeDifference < 0) {
        clearInterval(interval);
        setIsOpen(true);
        setCountdown('');
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [cashout]);

  const navigate = useNavigate();

  return (
    <div style={{ width: '100%' }}>
      <PageTitle>DOMINIUM</PageTitle>

      <PageWrapper>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={doGroups}>
            Grupos de WhatsApp
            <WhatsAppIcon />
          </Button>
        </div>
      </PageWrapper>
      {/* <PageWrapperFluid>
        <h2>Passe de batalha</h2>
        <p>
          O Passe de batalha é uma forma de distribuir os lucros do jogo entre
          nossos jogadores. Para criar um servidor estável, é distribuído o
          lucro apenas para os jogadores que conseguiram obter Tokens do Mestre.
        </p>

        <div
          className={classes.info}
          style={{
            padding: '40px 0px',
          }}
        >
          <LabelFragment
            title="Cofre do passe"
            value={`R$ ${bank.toFixed(2)}`}
          />
          <LabelFragment title="Competidores" value={premiumPlayers} />
          <LabelFragment
            title="Tokens em circulação"
            value={totalTokenAmount}
          />
        </div>
        <div className={classes.info}>
          <LabelFragment
            title="Valor do token"
            value={`R$ ${(totalTokenAmount !== 0
              ? bank / totalTokenAmount
              : bank
            ).toFixed(2)}`}
            isHighlight
            isLarge
            hasIcon
          />
          <LabelFragment
            title="Próximo pagamento"
            value={
              cashout
                ? new Date(cashout).toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : 'HORA DO PAGAMENTO'
            }
            isLarge
          />
        </div>
      </PageWrapperFluid>

      <PageWrapperFluid>
        <h2>Meus lucros</h2>
        {!isAuth && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 1000,
            }}
          >
            <Button highlight onClick={doLogin}>
              Faça login para visualizar
            </Button>
          </div>
        )}
        <div
          className={classes.myInfo}
          style={{
            filter: !isAuth ? 'blur(0.6rem)' : '',
          }}
        >
          <LabelFragment title="Meus tokens" value={tokenAmount} hasIcon />
          <LabelFragment
            title="Valor do token"
            value={`R$ ${(totalTokenAmount !== 0
              ? bank / totalTokenAmount
              : bank
            ).toFixed(2)}`}
          />
          <LabelFragment
            title="Meu pagamento"
            value={`R$ ${(
              (totalTokenAmount !== 0 ? bank / totalTokenAmount : 0) *
              tokenAmount
            ).toFixed(2)}`}
            isHighlight
          />
        </div>
      </PageWrapperFluid> */}
      {isOpen && (
        <PageWrapperFluid>
          <h2>Estatísticas</h2>
          <div
            className={classes.info}
            style={{
              padding: '40px 0px',
            }}
          >
            <LabelFragment
              title="Contas criadas"
              value={statistics ? statistics.accounts : 0}
            />
            <LabelFragment
              title="Personagens criados"
              value={statistics ? statistics.players : 0}
            />
            <LabelFragment
              title="Guildas criadas"
              value={statistics ? statistics.guilds : 0}
            />
          </div>
          <div className={classes.info}>
            <LabelFragment
              title="Online agora"
              value={statistics ? statistics.online : 0}
              isHighlight
              isLarge
            />
            <LabelFragment
              title="Online 24hrs"
              value={statistics ? statistics.lastPlayers : 0}
              isLarge
            />
          </div>
        </PageWrapperFluid>
      )}
      {!isOpen && (
        <PageWrapperFluid>
          <h2>Abertura Metin2 DOMINIUM</h2>
          <p>O login será liberado em {countdown}</p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              justifyContent: 'center',
            }}
          >
            <Button onClick={() => navigate('/cadastrar')}>
              Crie sua conta gratuita
            </Button>
            <Button onClick={() => navigate('/baixar')}>
              Faça o download do jogo
            </Button>
          </div>
        </PageWrapperFluid>
      )} 
    </div>
  );
};

export default Contest;
