import { useNavigate } from 'react-router-dom';
import ContestSvg from '../../Assets/Svgs/ContestSvg';
import DownloadSvg from '../../Assets/Svgs/DownloadSvg';
import RankingSvg from '../../Assets/Svgs/RankingSvg';
import WikiSvg from '../../Assets/Svgs/WikiSvg';
import Sideitem from '../Sideitem';
import classes from './index.module.css';
import { useEffect, useState } from 'react';
import SignUpSvg from '../../Assets/Svgs/SignUpSvg';

export function Sidebar() {
  const [active, setActive] = useState('/');
  const navigate = useNavigate();

  useEffect(() => {
    setActive(window.location.pathname);
  }, [navigate]);

  const menuClick = (link) => {
    navigate(link);
  };

  return (
    <div className={classes.root}>
      <Sideitem
        onClick={() => menuClick('/')}
        icon={<ContestSvg />}
        name="Estatísticas"
        isActive={active === '/'}
      />
      <Sideitem
        onClick={() => menuClick('/cadastrar')}
        icon={<SignUpSvg />}
        name="Cadastro"
        isActive={active === '/cadastrar'}
      />
      <Sideitem
        onClick={() => menuClick('/baixar')}
        icon={<DownloadSvg />}
        name="Download"
        isActive={active === '/baixar'}
      />
      <Sideitem
        onClick={() => menuClick('/ranking')}
        icon={<RankingSvg />}
        name="Rankings"
        isActive={active === '/ranking'}
      /> 
    </div>
  );
}
